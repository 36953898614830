<script setup lang="ts">

defineProps<{
    label: string
}>()
const counter = defineModel({
    type: Number,
    required: true
})

function decrement (): void {
    if (counter.value > 1) {
        counter.value -= 1
    }
}
</script>

<template>
    <div class="hz-the-counter">
        <label class="pre-title">
            {{ label }}
        </label>

        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="d-flex align-items-center">
                <div
                    class="btn btn-items btn-items-decrease font-weight-bold"
                    @click="decrement"
                >
                    -
                </div>

                <input
                    :value="counter"
                    disabled
                    class="form-control input-items"
                >

                <div
                    class="btn btn-items btn-items-increase font-weight-bold"
                    @click="counter += 1"
                >
                    +
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>