<script setup lang="ts">

import { RoomType } from '@/Pages/the-home/enums/Search'

const roomType = defineModel({
    type: String,
    default: RoomType.DORM
})

const emit = defineEmits(['roomTypeClick'])

</script>

<template>
    <div class="hz-room-type-switcher">
        <label class="hz-room-type-switcher-title pre-title mb-2">Room type</label>

        <div class="bs-switcher mb-2 mb-lg-0">
            <span class="bs-switcher__item">
                <input
                    id="hz-switcher-dorm"
                    v-model="roomType"
                    type="radio"
                    :value="RoomType.DORM"
                    @click="emit('roomTypeClick', RoomType.DORM)"
                >
                                                    
                <label for="hz-switcher-dorm">Dorm Bed</label>
            </span>

            <span class="bs-switcher__item">
                <input
                    id="hz-switcher-private"
                    v-model="roomType"
                    type="radio"
                    :value="RoomType.PRIVATE"
                    @click="emit('roomTypeClick', RoomType.PRIVATE)"
                >
                                                    
                <label for="hz-switcher-private">Private Room</label>
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>