<script setup lang="ts">
import VueDatePicker, { DatePickerMarker } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '../../sass/vue-datepicker-variables.scss'
import dayjs from 'dayjs'
import { onMounted, ref } from 'vue'
import { BrowserInfo, detectBrowserAndPlatform } from '@/Composables/browserInfo'

type Props = {
    dark?: boolean,
    multiCalendars?: boolean
}

const emit = defineEmits(['datesSelected', 'skipDates'])

const dates = defineModel({
    type: Array as () => string[],
    required: true
})

withDefaults(defineProps<Props>(), {
    dark: false,
    multiCalendars: true
})

const browserInfo = ref<BrowserInfo>({ browser: 'Unknown', platform: 'Unknown' })
const markers = ref<DatePickerMarker[]>([])
const localCopyOfDates = ref<string[]>([])

const addMarker = (date: string): void => {
    const startDate = dayjs(localCopyOfDates.value[0])
    const endDate = dayjs(date)
    const diffDays = endDate.diff(startDate, 'day') + 1
    const diffNights = diffDays - 1

    const dayLabel = diffDays === 1 ? 'Day' : 'Days'
    const nightLabel = diffNights === 1 ? 'Night' : 'Nights'

    markers.value = [{
        date,
        type: undefined,
        tooltip: [{ text: `${ diffDays } ${ dayLabel } / ${ diffNights } ${ nightLabel }` }]
    }]
}

const handleMouseOver = (e: Event): void => {
    const dateElement = (e.target as HTMLElement).closest('.dp__calendar_item')

    if (dateElement) {
        const ariaDisabled = dateElement.getAttribute('aria-disabled')
        const date = dateElement.getAttribute('id')

        if (date && ariaDisabled !== 'true' && date > localCopyOfDates.value[0]) {
            addMarker(date)
        }
    }
}

onMounted(() => {
    browserInfo.value = detectBrowserAndPlatform()

    localCopyOfDates.value = [dayjs(dates.value[0]).format('YYYY-MM-DD'), dayjs(dates.value[1]).format('YYYY-MM-DD')]
})

const setStartDate = (date: string): void => {
    localCopyOfDates.value[0] = dayjs(date).format('YYYY-MM-DD')

    markers.value = []
}
</script>

<template>
    <VueDatePicker
        v-if="browserInfo.platform === 'iOS'"
        v-model="dates"
        inline
        range
        auto-apply
        :multi-calendars="multiCalendars"
        :dark="dark"
        :min-date="dayjs().format('YYYY-MM-DD')"
        month-name-format="long"
        format="yyyy-MM-dd - yyyy-MM-dd"
        :enable-time-picker="false"
        min-range="1"
        @range-end="$emit('datesSelected')"
    />
    <VueDatePicker
        v-else
        v-model="dates"
        inline
        range
        auto-apply
        :multi-calendars="multiCalendars"
        :dark="dark"
        :min-date="dayjs().format('YYYY-MM-DD')"
        month-name-format="long"
        format="yyyy-MM-dd - yyyy-MM-dd"
        :enable-time-picker="false"
        :markers="markers"
        min-range="1"
        @mouseover="handleMouseOver"
        @range-end="emit('datesSelected')"
        @range-start="setStartDate"
    />
</template>

<style lang="scss" scoped>
:deep(.dp__menu) {
    border: none;
}

:deep(.dp__marker_tooltip) {
    background-color: #dee2e6;
    border-radius: 2px;
    padding: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

:deep(.dp__tooltip_text) {
    background-color: #dee2e6;
    color: #212529;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 5px 8px;
}

:deep(.dp__arrow_bottom_tp) {
    background-color: #dee2e6;
}

:deep(.dp__tooltip_mark) {
    display: none;
}
</style>
